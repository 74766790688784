import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';
import ChangePassword from 'src/views/auth/LoginView/Change_Password';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  CardHeader,
  SvgIcon,
  Hidden,
  Dialog,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Label from 'src/components/Label';
import { CheckCircle, ChevronDown } from 'react-feather';
import Skeleton from '@material-ui/lab/Skeleton';
import useTerm from 'src/hooks/useTerm';
import Rules from 'src/Rules.json'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 35,
    width: 35,
    marginRight: theme.spacing(1)
  },
  // popover: {
  //   width: 200
  // },
  menuItem: {
    color: theme.palette.background.dark,
    borderRadius: '4px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 5,

    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 2,
    paddingLeft: 3,
    borderRadius: '4px',
    backgroundColor: Rules.nav_top_bar.navbar_button
  }
}));
const noPointer = { cursor: 'pointer' };

const Account = image => {
  const { setTerm, term } = useTerm();

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  console.log(user);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen1] = useState(false);
  const handleClickOpen = async data => {
    try {
      handleClose();
      await setTerm('exam', 'Term Type', data);
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };
  const handleClickClose = () => {
    setOpen1(false);
  };

  return (
    <>
      {term ? (
        <CardHeader
          className={classes.item}
          style={noPointer}
          title={
            <Box display="flex" onClick={handleOpen} ref={ref}>
              <Box>
                <Box display="flex">
                  <Box mr={0.5} />

                  <Typography variant="h6" textAlign="left" color="white">
                    {getName(term)}
                  </Typography>

                  <Box mr={0.5} />
                  <Hidden smDown>
                    {term && (
                      <SvgIcon cursor="pointer">
                        <ChevronDown
                          strokeWidth="2px"
                          size="20px"
                          color="white"
                        />
                      </SvgIcon>
                    )}
                  </Hidden>
                </Box>
              </Box>
            </Box>
          }
        ></CardHeader>
      ) : (
        <Skeleton width={60} height={60} mr={2} />
      )}

      <Menu
        onClose={handleClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '180px',
            padding: '10px 18px 8px 18px',
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              borderRadius: '8px',
              backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('all')}
        >
          <Box display="flex" width="100%">
            <Typography variant="h5">All</Typography>
            <Box flexGrow={1} />
            {term == 'all' && (
              <CheckCircle size="20px" strokeWidth="3px" color="#002F6D" />
            )}

            {/* <Label color="success">Active</Label>  */}
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('odd')}
        >
          <Box display="flex" width="100%">
            <Typography variant="h5">Odd</Typography>
            <Box flexGrow={1} />
            {term == 'odd' && (
              <CheckCircle size="20px" strokeWidth="3px" color="#002F6D" />
            )}

            {/* <Label color="success"> <Typography variant="caption">Active</Typography></Label> */}
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={e => handleClickOpen('even')}
        >
          <Box display="flex" width="100%">
            <Typography variant="h5">Even</Typography>
            <Box flexGrow={1} />
            {term == 'even' && (
              <CheckCircle size="20px" strokeWidth="3px" color="#002F6D" />
            )}

            {/* <Label color="error">InActive</Label> */}
          </Box>
        </MenuItem>
      </Menu>
      <Dialog maxWidth="sm" fullWidth onClose={handleClickClose} open={open}>
        <ChangePassword open={handleClickClose} />
      </Dialog>
    </>
  );
};

export default Account;
