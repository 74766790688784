/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  SvgIcon,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
  Button
} from '@material-ui/core';
import Account from 'src/layouts/DashboardLayout/TopBar/Account';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Search from 'src/layouts/DashboardLayout/TopBar/Search';
import Help from 'src/layouts/DashboardLayout/TopBar/Help';
import Year from 'src/layouts/DashboardLayout/TopBar/Year';
import BlueButton from 'src/components/Buttons/RoundOutlineBlue';
import Settings from 'src/layouts/DashboardLayout/TopBar/SettingsTop';
import Term from 'src/layouts/DashboardLayout/TopBar/Term';
import Stream from 'src/layouts/DashboardLayout/TopBar/Stream';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Notifications from 'src/layouts/DashboardLayout/TopBar/Notifications';
import SettingsTop from 'src/layouts/DashboardLayout/TopBar/SettingsTop';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateRangeIcon from '@material-ui/icons/DateRangeOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CastForEducationOutlined from '@material-ui/icons/CastForEducationOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ViewStreamOutlinedIcon from '@material-ui/icons/ViewStreamOutlined';
import { ChevronLeft } from 'react-feather';
import useStream from 'src/hooks/useStream';
import useTerm from 'src/hooks/useTerm';
import useYear from 'src/hooks/useYear';
import Loader from 'src/components/LoadingScreen';
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#4F4E7D'
  }
})(AppBar);
const Users = {
  // subheader: 'Admission Settings Panel',
  items: [
 
    {
      title: 'General Settings',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Institution',
          href: '/app/admin/settings/general',
          icon: BusinessIcon
        },
        {
          title: 'User Management',
          href: '/app/admin/settings/user_management',
          icon: SwapVerticalCircleOutlinedIcon
        },
        
        {
          title: 'Notification Templates',
          href: '/app/admin/settings/templates',
          icon: DescriptionIcon
        }
      ]
    },
    {
      title: 'Academic Settings',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Programs',
          href: '/app/admin/settings/programs',
          icon: ClassOutlinedIcon
        },
        {
          title: 'Quota',
          href: '/app/admin/settings/quota',
          icon: CastForEducationOutlined
        },
        {
          title: 'Stream',
          href: '/app/admin/settings/stream',
          icon: ViewStreamOutlinedIcon
        },
        {
          title: 'Batch',
          href: '/app/admin/settings/batch',
          icon: GroupWorkOutlinedIcon
        },
        {
          title: 'Academic Year',
          href: '/app/admin/settings/academicyear',
          icon: DateRangeIcon
        },
        
      ]
    },
    {
      title: 'Exam Settings',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Fee Type',
          href: '/app/admin/settings/fee_settings/fee_type',
          icon: BusinessIcon
        },
        {
          title: 'Fee Schedule',
          href: '/app/admin/settings/feeschedule',
          icon: SwapVerticalCircleOutlinedIcon
        },
        
        {
          title: 'Exam Schedule',
          href: '/app/admin/settings/schedule',
          icon: DescriptionIcon
        }
      ]
    },
    {
      title: 'Seating Configuration',
      href: '/app/admin/settings',
      // icon: SettingsSuggestRoundedIcon,
      items: [
        {
          title: 'Room Configuration',
          href: '/app/admin/settings/room_settings/Room',
          icon: BusinessIcon
        },
        {
          title: 'Block Configuration',
          href: '/app/admin/settings/room_settings/Block',
          icon: SwapVerticalCircleOutlinedIcon
        },
        
      ]
    },
    // {
    //   title: 'Academic',
    //   href : '/app/admin/user_management',
    //   icon: SwapVerticalCircleOutlinedIcon,

    // },

    // {
    //   title: 'Quick Admission',
    //   href: '/app/admin/settings/quick_admission',
    //   icon: AssignmentTurnedInOutlinedIcon
    // },
  ]
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: `${item.href}/*`,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  statusField: {
    textDecoration: 'none'
    // variant:"h3"
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));


function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: `${item.href}/*`,
      exact: false
    });

    acc.push(
      <NavItem
       
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, open, setOpen, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { stream } = useStream();
  const { year } = useYear();
  const { term } = useTerm();
  const { user } = useAuth();
  // console.log(open);
  let users = '';
  const isMountedRef = useIsMountedRef();

  const [image, setImage] = useState(null);

  const [file, setFile] = React.useState([]);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id'];
  var bucketName = 'erpinstitution';
  let img;
  let logo;
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
      .getObject({
        Bucket: bucketName,
        Key: image_path
      })
      .promise()
      .then(res => {
        if (res) {
          // console.log("logo",res)
          return res;
        } else {
          return null;
        }
      })
      .catch(err => {
        // console.log(err);
      });
    return img;
  }

  const getCustomers = useCallback(async () => {
    try {
      const get_image = gql`
        query get_setup {
          get_setup {
            _id
            institution_logo
          }
        }
      `;
      var image_path =
        encodeURIComponent(folderName) +
        '/' +
        encodeURIComponent('logo') +
        '/' +
        user['custom:institution_id'];

      img = await get_logo(image_path);
      if (img === undefined || img === null) {
        logo = null;
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        setImage(logo);
      }
    } catch (err) {
      // console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  window.addEventListener('storage', function(e) {
    // console.log('storage fired');
    // this.fetchData();
  });

  window.dispatchEvent(new Event('storage'));

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box px={2} py={1}>
        <BlueButton
          variant="outlined"
          color="secondary"
          size="medium"
          fullWidth
          component={RouterLink}
          to={{
            pathname: `/app/exam/home`
          }}
          startIcon={
            <SvgIcon fontSize="small">
              <ChevronLeft strokeWidth="2px" size="24px" />
            </SvgIcon>
          }
        >
          Back to Main Menu
        </BlueButton>
      </Box>

      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} pt={0}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>
        <Divider />
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );

  return (
    <div className={classes.root}>
      {(stream == null || year == null || term == null) && (
        <Loader open={true} />
      )}
      <CssBaseline />
      <ColorAppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          {open === true && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          {open === false && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuOpenIcon fontSize="large" />
            </IconButton>
          )}

          <Stream image={image} />

          <Box ml={2} flexGrow={1} />
          <Year />
<Term/>

          <Notifications />
          {/* <Help /> */}
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </ColorAppBar>
      {
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {content}
        </Drawer>
      }
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
