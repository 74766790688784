import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DashboardLayoutSettings from 'src/layouts/DashboardLayoutSettings';
import MainLayout from 'src/layouts/MainLayout';
import Loader from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import SettingsGuard from 'src/components/Guards/SetingsGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader open={true} />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/trialTest/:link_token',
    component: lazy(() => import('src/views/ExternalTest'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,

    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() =>
      import('src/views/auth/LoginView/Confirm_forgot_password')
    )
  },

  {
    path: '/app/admin/settings/fee_settings',
    guard: AuthGuard,
    layout: DashboardLayoutSettings,
    routes: [
      {
        exact: true,
        path: '/app/admin/settings/fee_settings/fee_type',
        // layout : FeeSettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/FeeSetup/FeeType'))
      },

      {
        exact: true,
        path: '/app/admin/settings/fee_settings',
        // layout : FeeSettingsLayout,
        guard: SettingsGuard,
        component: () => (
          <Redirect to="/app/admin/settings/fee_settings/fee_type" />
        )
      }
    ]
  },
  {
    path: '/app/admin/settings/room_settings',
    guard: AuthGuard,
    layout: DashboardLayoutSettings,
    routes: [
      {
        exact: true,
        path: '/app/admin/settings/room_settings/Room',
        //  layout : RoomSettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/RoomSetup/Room'))
      },
      {
        exact: true,
        path: '/app/admin/settings/room_settings/Block',
        //  layout : RoomSettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/RoomSetup/Block'))
      },

      {
        exact: true,
        path: '/app/admin/settings/room_settings',
        //  layout : RoomSettingsLayout,
        guard: SettingsGuard,
        component: () => (
          <Redirect to="/app/admin/settings/room_settings/Room" />
        )
      }
    ]
  },
  {
    path: '/app/admin/settings',
    guard: AuthGuard,
    layout: DashboardLayoutSettings,
    routes: [
      {
        exact: true,
        path: '/app/admin/settings/programs',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Programs'))
      },
      {
        exact: true,
        path: '/app/admin/settings/user_management',
        component: lazy(() => import('src/views/UserManagement'))
      },
      {
        exact: true,
        path: '/app/admin/settings/general',
        component: lazy(() => import('src/views/Setup/Details'))
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule',
        component: lazy(() => import('src/views/ExamSetup'))
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/AddSchedule',
        component: lazy(() => import('src/views/ExamSetup/AddExam'))
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewSchedule',
        component: lazy(() => import('src/views/ExamSetup/ViewSchedule'))
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewLabSchedule',
        component: lazy(() => import('src/views/ExamSetup/ViewLabSchedule'))
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewLabSchedule/add',
        component: lazy(() =>
          import('src/views/ExamSetup/ViewLabSchedule/AddLab')
        )
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewLabSchedule/addBatch',
        component: lazy(() =>
          import('src/views/ExamSetup/ViewLabSchedule/AddBatch')
        )
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewLabSchedule/viewBatch',
        component: lazy(() =>
          import('src/views/ExamSetup/ViewLabSchedule/ViewBatch')
        )
      },
      {
        exact: true,
        path: '/app/admin/settings/schedule/ViewSchedule/DaySchedeule',
        component: lazy(() => import('src/views/ExamSetup/ViewSchedule/Day'))
      },
      {
        exact: true,
        path: '/app/admin/settings/feeschedule',
        component: lazy(() => import('src/views/Setup/FeeSchedule'))
      },
      {
        exact: true,
        path: '/app/admin/settings/feeschedule',
        component: lazy(() => import('src/views/Setup/FeeSchedule'))
      },
      {
        exact: true,
        path: '/app/admin/settings/addfeeschedule',
        component: lazy(() => import('src/views/Setup/FeeSchedule/Ongoing'))
      },
      {
        exact: true,
        path: '/app/admin/settings/quota',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Quota'))
      },
      {
        exact: true,
        path: '/app/admin/settings/stream',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Stream'))
      },
      {
        exact: true,
        path: '/app/admin/settings/stream/addStream',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Stream/AddStream'))
      },
      {
        exact: true,
        path: '/app/admin/settings/stream/:id',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Stream/Details'))
      },
      {
        exact: true,
        path: '/app/admin/settings/stream/:id/edit',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Stream/StreamEditView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/batch',
        // layout : SettingsLayout,
        component: lazy(() => import('src/views/Setup/Batch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/batch/addBatch',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Batch/AddBatch'))
      },
      {
        exact: true,
        path: '/app/admin/settings/batch/:id',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/batch/:id/edit',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/Batch/BatchEditView'))
      },
      {
        exact: true,
        path: '/app/admin/settings/academicyear',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: lazy(() => import('src/views/Setup/AcademicYear'))
      },

      {
        exact: true,
        path: '/app/admin/settings',
        // layout : SettingsLayout,
        guard: SettingsGuard,
        component: () => <Redirect to="/app/admin/settings/programs" />
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/Notifications',
        component: lazy(() => import('src/views/Notifications'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam',
        component: lazy(() => import('src/views/Tasks'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam/view/:exam_id',
        component: lazy(() => import('src/views/Tasks/Group'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam/viewStudents/:seating_id',
        component: lazy(() => import('src/views/Tasks/StudentList'))
      },
      {
        exact: true,
        path: '/app/exam/onlineExam/bulkUploadSeating/:exam_id',
        component: lazy(() =>
          import('src/views/Tasks/Group/BulkUploadSeating')
        )
      },
      {
        exact: true,
        path: '/app/exam/onlineExam/bulkUploadSeating/:exam_id/:seating_id',
        component: lazy(() =>
          import('src/views/Tasks/Group/BulkUploadSeatingRoom')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/admin/academics/tasks/examAttendance/:seating_id',
      //   component: lazy(() => import('src/views/Tasks/Test'))
      // },
      {
        exact: true,
        path: '/app/onlineExam/:mode',

        component: lazy(() => import('src/views/OnlineExamSchedule'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session/:seating_id/room',

        component: lazy(() => import('src/views/OnlineExamRoom'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating',

        component: lazy(() => import('src/views/OnlineExamSeating'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session',

        component: lazy(() => import('src/views/OnlineExamSession'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session/bulkUpload',

        component: lazy(() => import('src/views/OnlineExamSession'))
      },
      {
        exact: true,
        path: '/app/exam/schedule',

        component: lazy(() => import('src/views/Schedule'))
      },
      {
        exact: true,
        path: '/app/exam/DailySchedule',

        component: lazy(() => import('src/views/DailySchedule'))
      },

      {
        exact: true,
        path: '/app/exam/DailySchedule/:exam_id/Seating',

        component: lazy(() => import('src/views/DailySchedule/ScheduleIndex'))
      },
      {
        exact: true,
        path: '/app/exam/DailySchedule/Seating/lab',

        component: lazy(() => import('src/views/DailySchedule/LabSeating'))
      },
      {
        exact: true,
        path: '/app/DailySchedule/seating/SeatAllotment',

        component: lazy(() =>
          import('src/views/DailySchedule/SeatingArrangment')
        )
      },
      {
        exact: true,
        path: '/app/DailySchedule/seating/bulkUpload/:id/:schedule_id/:session',

        component: lazy(() =>
          import('src/views/DailySchedule/BulkUploadSeating')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/DailySchedule/duty/:id/:schedule_id/:session',

      //   component: lazy(() => import('src/views/DailySchedule/EmployeesOnDuty'))
      // },
      {
        exact: true,
        path: '/app/DailySchedule/seating/:id/FormA',

        component: lazy(() => import('src/views/DailySchedule/FormA'))
      },
      {
        exact: true,
        path: '/app/DailySchedule/seating/FormA/Attendence',

        component: lazy(() =>
          import('src/views/DailySchedule/FormA/MarkAttendence')
        )
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/:status',
        component: lazy(() => import('src/views/Schedule/DraftScheduleFee'))
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/active',
        component: lazy(() => import('src/views/Schedule/ActiveFeeSchedule'))
      },
      {
        exact: true,
        path: '/app/exam/home',
        component: lazy(() => import('src/views/DashboardNew'))
      },
    
    
     
      {
        exact: true,
        path: '/app/admin/students/tansactions',
        component: lazy(() => import('src/views/TransactionsNew'))
      },
     
     
      {
        exact: true,
        path: '/app/fee/remainders',
        component: lazy(() => import('src/views/Remainders'))
      },
      {
        exact: true,
        path: '/app/examfee/reports',
        component: lazy(() => import('src/views/Reports'))
      },
      {
        exact: true,
        path: '/app/admin/AllStudents',
        component: lazy(() => import('src/views/AllStudentList'))
      },
      {
        exact: true,
        path: '/app/admin/AllStudents/Detail/:student_id',
        component: lazy(() => import('src/views/AllStudentList/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/students/:admission_status',
        component: lazy(() => import('src/views/AllStudents'))
      },
      {
        exact: true,
        path: '/app/admin/students/:admission_status/:student_id',
        component: lazy(() => import('src/views/AllStudents/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/students/:admission_status/:student_id/orders/:order_id',
        component: lazy(() => import('src/views/AllStudents/Orders/OrderDetailPage'))
      },
       {
        exact: true,
        path: '/app/admin/students/fee/reports/list',
        component: lazy(() => import('src/views/FeeReports'))
      },
      {
        exact: true,
        path: '/app/results/student_wise/:schedule_id',
        component: lazy(() => import('src/views/ResultEntryStudent'))
      },
      {
        exact: true,
        path: '/app/results/student_wise/marks/:schedule_id',
        component: lazy(() => import('src/views/ResultEntryMarks'))
      },
      {
        exact: true,
        path: '/app/results/student_wise',
        component: lazy(() => import('src/views/ResultEntryStudent/Schedules'))
      },
      {
        exact: true,
        path: '/app/results/sem_wise',
        component: lazy(() => import('src/views/ResultEntrySem'))
      },
      {
        exact: true,
        path: '/app/results/revaluation',
        component: lazy(() => import('src/views/ResultEntryReval'))
      },
      {
        exact: true,
        path: '/app/admin/result_entry',
        component: lazy(() => import('src/views/ResultEntryStudent/Schedules'))
      },
      {
        exact: true,
        path: '/app/admin/result_entry/marks',
        component: lazy(() => import('src/views/ResultEntryMarks/Schedules'))
      },
      {
        exact: true,
        path: '/app/admin/revaluation_result_entry/allStudents/:schedule_id',
        component: lazy(() =>
          import('src/views/ResultEntryStudent/RVResultEntry')
        )
      },
      {
        exact: true,
        path: '/app/admin/student_detail/:student_id/:admission_status',
        component: lazy(() => import('src/views/AllStudents/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBook',
        component: lazy(() => import('src/views/Reports/DayBookReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DayBookConvocation',
        component: lazy(() => import('src/views/Reports/ConcocationDCR'))
      },
      {
        exact: true,
        path: '/app/admin/reports/RevaluationDayBook',
        component: lazy(() =>
          import('src/views/Reports/RevaluationDayBookReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/appearinglistreport',
        component: lazy(() => import('src/views/Reports/appearinglistreport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/DeptStaffDetails',
        component: lazy(() => import('src/views/Reports/DeptStaffDetails'))
      },
      {
        exact: true,
        path: '/app/admin/reports/SessionStaffDetails',
        component: lazy(() => import('src/views/Reports/SessionStaffDetails'))
      },
      {
        exact: true,
        path: '/app/admin/reports/ia_seating_report',
        component: lazy(() => import('src/views/Reports/IASeatingReport'))
      },
      {
        exact: true,
        path: '/app/admin/reports/result_analysis_department_wise_report',
        component: lazy(() =>
          import('src/views/Reports/ResultAnalysisDepartmentWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/result_analysis_course_wise_report',
        component: lazy(() =>
          import('src/views/Reports/ResultAnalysisCourseWiseReport')
        )
      },
      {
        exact: true,
        path: '/app/admin/reports/exam_report',
        component: lazy(() => import('src/views/Reports/ExamReport'))
      },

      {
        exact: true,
        path: '/app/admin/reports/FeeNotPaid',
        component: lazy(() => import('src/views/Reports/FeeNotPaid'))
      },
      {
        exact: true,
        path: '/app/admin/reports/CandidateList',
        component: lazy(() => import('src/views/Reports/CandidateList'))
      },
      // {
      //   exact: true,
      //   path: '/app/admin/reports/DemandCollection',
      //   component: lazy(() => import('src/views/Reports/DemandCollectionReport'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/reports/Refund',
      //   component: lazy(() => import('src/views/Reports/RefundReport'))
      // },
      // {
      //   exact: true,
      //   path: '/app/admin/reports/ConsolidateBalance',
      //   component: lazy(() => import('src/views/Reports/ConsolidatedBalanceReport'))
      // },
      {
        exact: true,
        path: '/app/admin/reports',
        component: lazy(() => import('src/views/Reports'))
      }
    ]
  },
  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
