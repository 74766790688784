/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Account from './Account';
import Settings from './SettingsTop';
import Stream from './Stream';
import Year from './Year';
import Term from './Term';
import NavItem from './NavItem';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useStream from 'src/hooks/useStream';
import useTerm from 'src/hooks/useTerm';
import useYear from 'src/hooks/useYear';
import Loader from 'src/components/LoadingScreen';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Rules from 'src/Rules.json'


const ColorAppBar = withStyles({
  root: {
    backgroundColor: Rules.nav_top_bar.navbar_background_color
  }
})(AppBar);

const dasboard = {
  // subheader: 'Exam Management Panel',
  items: [
    {
      title: 'Dashboard',
      href: '/app/exam/home',
      icon: `material-symbols-light:dashboard-outline`
    }
  ]
};

const Users = {
  items: [
    // {
    //   title: 'Students',
    //   href: '/app/admin/AllStudents',
    //   icon: 'ph:student-thin'
    // },
    {
      title: 'Collect Fee',
      href: '/app/admin/students',
      icon: `healthicons:money-bag-outline`,
      items: [
        {
          title: 'Regular Students',
          href: '/app/admin/students/live',
          icon: 'fluent:people-team-20-regular'
        },
        {
          title: 'Output Students',
          href: '/app/admin/students/completed',
          icon: 'pepicons-pencil:people'
        },
        {
          title: 'Transactions',
          href: '/app/admin/students/tansactions',
          icon: 'carbon:cics-transaction-server-zos'
        },
        {
          title: 'Fee Tracker',
          href: '/app/exam/schedule',
          icon: 'carbon:event-schedule'
        },
        {
          title: 'Reports',
          href: '/app/admin/students/fee/reports/list',
          icon: 'iconoir:reports'
        }
      ]
    },
    {
      title: 'Results',
      href: '/app/results',
      icon: 'ph:bookmarks-simple-light',
      items: [
        {
          title: 'Student Wise',
          href: '/app/results/student_wise',
          icon: 'fluent:people-audience-20-regular'
        },
        {
          title: 'Term Wise',
          href: '/app/results/sem_wise',
          icon: 'lets-icons:group-share-light'
        },
        {
          title: 'Revaluation',
          href: '/app/results/revaluation',
          icon: 'iwwa:reset'
        },
        // {
        //   title: 'Analysis',
        //   href: '/app/onlineExam/offline',
        //   icon: 'ep:data-analysis'
        // }
      ]
    },
    {
      title: 'Exam Seating',
      href: '/app/onlineExam',
      icon: 'material-symbols-light:event-seat-outline-rounded',
      items: [
        {
          title: 'Online Exam',
          href: '/app/onlineExam/online',
          icon: 'material-symbols-light:online-prediction-rounded'
        },
        {
          title: 'Online Test',
          href: '/app/exam/onlineExam',
          icon: 'arcticons:readera'
        },
        {
          title: 'Offline Exam',
          href: '/app/onlineExam/offline',
          icon: 'ph:exam-light'
        }
      ]
    },

    // {
    //   title: 'Old Online Test',
    //   href: '/app/exam/onlineExam',
    //   icon: AssignmentTurnedInOutlinedIcon
    // },
    // {
    //   title: 'Old Offline Exam',
    //   href: '/app/exam/DailySchedule',
    //   icon: AssignmentTurnedInOutlinedIcon
    // },
    // {
    //   title: 'Notifications',
    //   href: '/app/Notifications',
    //   icon: 'mynaui:message-dots'
    // },
    {
      title: 'Reports',
      href: '/app/examfee/reports',
      icon: 'iconoir:reports'
    }
  ]
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, navOpen }) {
  const key = item.title + depth;

  if (item.items) {
    const expanded = matchPath(pathname, {
      path: `${item.href}/*`,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        navOpen={navOpen}
        open={Boolean(expanded)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          navOpen: navOpen,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: 800
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: 800
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: 800
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, open, setOpen, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const { stream } = useStream();
  const { year } = useYear();
  const { term } = useTerm();
  const { user } = useAuth();
  console.log(open);
  let users = '';

  const [image, setImage] = useState(null);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id'];
  var bucketName = 'erpinstitution';
  let img;
  let logo;
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
      .getObject({
        Bucket: bucketName,
        Key: image_path
      })
      .promise()
      .then(res => {
        if (res) {
          console.log('logo', res);
          return res;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log(err);
      });
    return img;
  }

  const getCustomers = useCallback(async () => {
    try {
      const get_image = gql`
        query get_setup {
          get_setup {
            _id
            institution_logo
          }
        }
      `;
      var image_path =
        encodeURIComponent(folderName) +
        '/' +
        encodeURIComponent('logo') +
        '/' +
        user['custom:institution_id'];

      img = await get_logo(image_path);
      if (img === undefined || img === null) {
        logo = null;
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        setImage(logo);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };

  window.addEventListener('storage', function(e) {
    console.log('storage fired');
    // this.fetchData();
  });
  console.log(window.dispatchEvent(new Event('storage')));

  window.addEventListener('storage', console.log);

  window.localStorage.setItem('test', '123');
  window.dispatchEvent(new Event('storage'));
  console.log('user.user_group', user.user_group);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box px={2} pb={1}>
          <Box mb={2} mt={2} ml={1.5} display="flex">
            {/* <SchoolRoundedIcon className={classes.red} />
              <Box mr={1} /> */}
            <Box>
              <Typography variant="h5" color="textPrimary">
                Rove Exams
              </Typography>
            </Box>
          </Box>

          <Box mt={-1} />
          <List
            key={dasboard.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {dasboard.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: dasboard.items,
              pathname: location.pathname,
              navOpen: open
            })}
          </List>
          <Box mt={-1} />
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname,
              navOpen: open
            })}
          </List>
        </Box>
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );

  return (
    <div className={classes.root}>
      {(stream == null || year == null || term == null) && (
        <Loader open={true} />
      )}
      <CssBaseline />
      <ColorAppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          {open === true && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          {open === false && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuOpenIcon fontSize="large" />
            </IconButton>
          )}

          <Stream image={image} />

          <Box ml={2} flexGrow={1} />
          <Year />
          <Term />
          <Notifications />
          {/* <Help /> */}
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </ColorAppBar>
      <Drawer
        classes={{ paper: classes.desktopDrawer }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {content}
      </Drawer>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
