import _ from 'lodash';
import { createTheme } from '@mui//material/styles';
import { colors, responsiveFontSizes } from '@mui/material';
import { THEMES } from 'src/constants';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import Rules from 'src/Rules.json';

const baseOptions = {
  direction: 'ltr',
  typography,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: '#9e9e9e',
        //   marginTop: '-1px',
          '&:hover': {
            borderColor: Rules.tabs.text
          },
          '&.Mui-focused': {
            color: Rules.tabs.text
          }
        },
        outlinedHover: {
          '&.Mui-focused': {
            color: Rules.tabs.text
          },
          '&:hover': {
            color: Rules.tabs.text
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
        //   '& .MuiInputLabel-root': {
        //     color: '#9e9e9e',
        //     '&.Mui-focused': {
        //       color: Rules.tabs.text
        //     },
        //     '&:hover': {
        //       color: Rules.tabs.text
        //     }
        //   },
        //   '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //       borderColor: '#9e9e9e'
        //     },
        //     '&:hover fieldset': {
        //       borderColor: Rules.tabs.text
        //     },
        //     '&.Mui-focused fieldset': {
        //       borderColor: Rules.tabs.text
        //     }
        //   },
        //   '& .MuiSelect-root': {
        //     '& fieldset': {
        //       borderColor: '#9e9e9e'
        //     },
        //     '&:focus': {
        //       borderColor: Rules.tabs.text
        //     },
        //     '&:hover': {
        //       borderColor: Rules.tabs.text
        //     }
        //   }
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
        //   '& .MuiInputLabel-root': {
        //     color: '#9e9e9e',
        //     '&.Mui-focused': {
        //       color: Rules.tabs.text
        //     },
        //     '&:hover': {
        //       color: Rules.tabs.text
        //     }
        //   },
        //   '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //       borderColor: '#9e9e9e'
        //     },
        //     '&:hover fieldset': {
        //       borderColor: Rules.tabs.text
        //     },
        //     '&.Mui-focused fieldset': {
        //       borderColor: Rules.tabs.text
        //     }
        //   },
        //   '& .MuiSelect-root': {
        //     '&:focus': {
        //       borderColor: Rules.tabs.text
        //     },
        //     '&:hover': {
        //       borderColor: Rules.tabs.text
        //     }
        //   }
        }
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: colors.blueGrey[600]
            }
          }
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  }
];

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
