import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles } from '@material-ui/core';
import getName from 'src/utils/getName'
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    borderRadius:'10px',
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(1.5, 1.5),
    textTransform: 'capitalize',
    backgroundColor:'#000000'
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: fade(theme.palette.primary.main, 0.08)
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.08)
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.08)
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: fade(theme.palette.success.main, 0.08)
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: fade(theme.palette.warning.main, 0.08)
  },
  verify: {
    color:"#03a9f4",
    backgroundColor: fade("#03a9f4", 0.08)
  },
  purple: {
    color:"#ba68c8",
    backgroundColor: fade("#ba68c8", 0.08)
  },
  grey:{
    color:"#424242",
    backgroundColor: fade("#424242", 0.08)
 
  },
  noBackground:{
    backgroundColor: 'transparent'
  },
  green: {
    color: '#43a047',
    backgroundColor: fade('#43a047', 0.08)
  },
  red: {
    color: '#e53935',
    backgroundColor: fade('#e53935', 0.08)
  },
  blue: {
    color: '#002F6D',
    backgroundColor: fade('#002F6D', 0.08)
  },
  dark:{
    color:'#717171',
    backgroundColor: theme.palette.background.dark
  }
}));

const Label = ({
  className = '',
  color = 'secondary',
  children,
  variant = 'default',
  style,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <span
      className={
        clsx(classes.root, {
          [classes[color]]: color,
          [classes.noBackground]: variant === 'text',
        }, className)
      }
      {...rest}
    >
      {children}
    </span>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success','verify','purple']),
};

export default Label;
