import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const FeeSetupContext = createContext({
  ...initialCreateState,
  addprogram: () => Promise.resolve(),
  addprogram1 : () => Promise.resolve()
});

export const FeeSetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const addprogram = async (data  
     ) => {
         console.log("hit")
   
    try {
      let response;
      response = await  axios.post("https://feeapi.rovelabs.com/import", data );
      console.log(response)
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user : response.data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err.response.data,
          updatesuccessfull : false
        }
      });
    }
      console.log(err.message);
    }
  };


  const addprogram1 = async (data  
    ) => {
        console.log("hit")
   try {
     let response;
     response = await  axios.post("https://feeapi.rovelabs.com/importData", data );
     console.log(response)
     dispatch({
       type: 'ADD_IMPORT',
       payload: {
         user1 : response.data,
         updatesuccessfull1 : true
       }
     });
   } catch (err) {
     console.log(err);
     if (err.response) {
     dispatch({
       type: 'ADD_IMPORT_ERROR',
       payload: {
         err1: err.response.data,
         updatesuccessfull1 : false
       }
     });
   }
     console.log(err.message);
   }
 };

  return (
    <FeeSetupContext.Provider
      value={{
        ...state,
        addprogram,
        addprogram1
      }}
    >
      {children}
    </FeeSetupContext.Provider>
  );
};

export default FeeSetupContext;