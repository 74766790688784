import ChangePassword from 'src/views/auth/LoginView/Change_Password';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  CardHeader,
  SvgIcon,
  Hidden,
  Dialog,
  Typography,
  makeStyles,
  fade
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Label from 'src/components/Label';
import { ChevronDown, CheckCircle } from 'react-feather';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Settingsclient from 'src/utils/GQLSettingsClient';
import useYear from 'src/hooks/useYear';
import Skeleton from '@material-ui/lab/Skeleton';
import Rules from 'src/Rules.json'

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.background.dark,
    borderRadius: '4px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 5,
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 2,
    paddingLeft: 3,
    borderRadius: '4px',
    backgroundColor: Rules.nav_top_bar.navbar_button
  }
}));
const noPointer = { cursor: 'pointer' };


const Account = () => {
  const isMountedRef = useIsMountedRef();
  const classes = useStyles();
  const history = useHistory();
  const { setYear, year } = useYear();

  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  console.log(user);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen1] = useState(false);
  const handleClickOpen = async data => {
    try {
      handleClose();
      await setYear('exam', 'Academic Year', data._id);
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };
  const handleClickClose = () => {
    setOpen1(false);
  };
  const [loading, setLoading] = useState(true);
  const [academicYears, setAcademicYears] = useState([]);
  const getAcademicYears = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          get_all_academic_years {
            year_name
            _id
            status
            term_type
          }
        }
      `;

      const { data } = await Settingsclient.query({
        query: getstudents,
        variables: {},
        fetchPolicy: 'network-only'
      }).then(res => {
        console.log(res);
        return res;
      });

      if (isMountedRef.current) {
        setAcademicYears(data.get_all_academic_years);
        const active = data.get_all_academic_years.find(
          x => x.status == 'active'
        );
        console.log(active);
        // setTermType(active.term_type)
        // setTimeRange(active.year_name)
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getAcademicYears();
  }, [getAcademicYears]);

  return (
    <>
      {/* <Card
        // className={clsx(classes.root, className)}
        // elevation={8}
        // {...rest}
      > */}
      {year ? (
        <CardHeader
          className={classes.item}
          style={noPointer}
          title={
            <Box
              display="flex"
              // alignItems="left"
              // component={ButtonBase}
              onClick={handleOpen}
              ref={ref}
              // className={classes.item}
            >
              <Box>
                <Box display="flex">
                  <Box mr={0.5} />

                  <Typography variant="h6" textAlign="left" color="white">
                    <Hidden smDown> Academic Year</Hidden> {year.year_name}
                  </Typography>

                  <Box mr={0.5} />
                  <Hidden smDown>
                    {year ? (
                      <SvgIcon cursor="pointer">
                        <ChevronDown
                          strokeWidth="2px"
                          size="20px"
                          color="white"
                        />
                      </SvgIcon>
                    ) : (
                      <></>
                    )}
                  </Hidden>
                </Box>
              </Box>
            </Box>
          }
        ></CardHeader>
      ) : (
        <Box mr={2}>
          <Skeleton width={120} height={60} />
        </Box>
      )}
      {/* </Card> */}

      <Menu
        onClose={handleClose}
        // keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 5,

          sx: {
            minWidth: '250px',
            padding: '10px 18px 8px 18px',
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              borderRadius: '8px',
              backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {year &&
          academicYears.map(x => (
            <MenuItem
              className={classes.menuItem}
              onClick={e => handleClickOpen(x)}
              // style={{ padding: '21px' }}
            >
              <Box display="flex" width="100%">
                <Typography variant="h5">{x.year_name}</Typography>
                <Box flexGrow={1} />
                {x.year_name == year.year_name && (
                  <CheckCircle size="20px" strokeWidth="3px" color="#002F6D" />
                )}
                {/* {getStatusLabel(x.status)} */}
                <Box mr={1} />
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default Account;
