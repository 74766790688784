import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import Rules from 'src/Rules.json';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiInputLabel: {
      outlined: {
        color: '#9e9e9e',
        '&:hover': {
          borderColor: Rules.tabs.text
        },
        '&.Mui-focused': {
          color: Rules.tabs.text // Change this to the desired label color when focused
        }
      },
      outlinedHover: {
        '&.Mui-focused': {
          color: Rules.tabs.text
        },
        '&:hover': {
          color: Rules.tabs.text // Change this to the desired label color on hover
        }
      }
    },
    // MuiSelect: {
    //   outlined: {
    //     '& .MuiOutlinedInput-notchedOutline': {
    //       borderColor: '#000000'
    //     },
    //     '&:hover .MuiOutlinedInput-notchedOutline': {
    //       borderColor: Rules.tabs.text
    //     },
    //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //       borderColor: Rules.tabs.text
    //     }
    //   },
    
    // },
    MuiTextField: {
      root: {
        '& .MuiInputLabel-root': {
          color: '#9e9e9e',
          '&.Mui-focused': {
            color: Rules.tabs.text
          },
          '&:hover': {
            color: Rules.tabs.text
          }
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#9e9e9e'
          },
          '&:hover fieldset': {
            borderColor: Rules.tabs.text
          },
          '&.Mui-focused fieldset': {
            borderColor: Rules.tabs.text
          }
        },
        '& .MuiSelect-root': {
          '& fieldset': {
            borderColor: '#9e9e9e'
          },
          '&:focus': {
            borderColor: Rules.tabs.text
          },
          '&:hover': {
            borderColor: Rules.tabs.text
          }
        }
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    template: {
      main: '#002250'
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        color1: '#fafafa',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600]
      },
      secondary: {
        main: '#5850EC'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  
  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
