import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ImportForCompanySetupContext = createContext({
  ...initialCreateState,
  uploadExamSessions: () => Promise.resolve(),
  uploadSeating: () => Promise.resolve(),
  uploadOnlineSeating: () => Promise.resolve(),
  NotifyStaff:()=>Promise.resolve()
});

export const ImportCompanySetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const NotifyStaff = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://reportapidev.rovelabs.com/report/StaffWiseSessions',
          data
        );
      } else {
        response = await axios.post(
          'https://reportapi.rovelabs.com/report/StaffWiseSessions',
          data
        );
      }
      console.log('response',response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        console.log('response',err.response);

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };

  const uploadSeating = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/exam_seating',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/exam_seating',
          data
        );
      }
      console.log('response',response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        console.log('response',err.response);

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };
  const uploadExamSessions = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/exam_time_table_upload',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/exam_time_table_upload',
          data
        );
      }
      console.log('response',response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        console.log('response',err.response);

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };


  const uploadOnlineSeating = async data => {
    // console.log("hit")

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/online_exam_seating',
          data
        );
      } else {
        response = await axios.post(
          'https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/online_exam_seating',
          data
        );
      }
      console.log('response',response);
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {
      //  console.log(err);
      if (err.response) {
        console.log('response',err.response);

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      //  console.log(err.message);
    }
  };

  return (
    <ImportForCompanySetupContext.Provider
      value={{
        ...state,
        uploadExamSessions,
        uploadSeating,
        uploadOnlineSeating,
        NotifyStaff,
      }}
    >
      {children}
    </ImportForCompanySetupContext.Provider>
  );
};

export default ImportForCompanySetupContext;
