import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback
} from 'react';
import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import client from 'src/utils/GQLCompanyClient';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Settingclient from 'src/utils/GQLSettingsClient';

require('dotenv').config();

const initialAuthState = {
  term: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TERM': {
      const { term } = action.payload;
      return {
        ...state,
        term
      };
    }

    default: {
      return { ...state };
    }
  }
};

const TermContext = createContext({
  ...initialAuthState,
  setTerm: () => Promise.resolve(),
  getTerm: () => Promise.resolve()
});

export const TermProvider = ({ children }) => {
  const history = useHistory();

  const isMountedRef = useIsMountedRef();

  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const setTerm = async (app, id, term) => {
    dispatch({
      type: 'SET_TERM',
      payload: {
        term: null
      }
    });
    try {
      const ADD = gql`
        mutation MyMutation(
          $app_name: String
          $session_key: String
          $session_value: String
        ) {
          add_session_value(
            app_name: $app_name
            session_key: $session_key
            session_value: $session_value
          ) {
            _id
          }
        }
      `;

      const { data } = await client
        .mutate({
          mutation: ADD,
          variables: {
            app_name: app,
            session_key: id,
            session_value: term
          }
        })
        .then(res => {
          console.log(res)
          history.push('/app/exam/home');

          window.location.reload();
          return res;
        });
    } catch (err) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }
      console.log(err.message);
    }
  };
  const getTerm = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          user_by_email_tenant {
            _id
            session_details {
              app_name
              session_key
              session_value
            }
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        const value = data.user_by_email_tenant.session_details.find(
          x =>
            x.app_name == 'exam' &&
            x.session_key == 'Term Type'
        );
        console.log('user_by_email_tenant', value);
        if (!value) {
          await setTerm(
            'exam',
            'Term Type',
            'odd'
          );
          window.location.reload();
        }
        else{
          dispatch({
            type: 'SET_TERM',
            payload: {
              term: value.session_value,
              isInitialised: true
            }
        })
      }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getTerm();
  }, [getTerm]);

  return (
    <TermContext.Provider
      value={{
        ...state,
        setTerm,
        getTerm
      }}
    >
      {children}
    </TermContext.Provider>
  );
};

export default TermContext;
