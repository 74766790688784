import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
//import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from "apollo-link-http";
import axios from 'axios';
import Cookies from 'js-cookie';
require('dotenv').config();

const cache = new InMemoryCache();
//const accessToken = localStorage.getItem('accessToken');
// Define Http link

// const httpLink = (PORT) => new HttpLink({
//     uri: `http://localhost:${PORT}/graphql`,
//     credentials: 'include',
   
//   });

const httpLink = (subdomain) => {
  if(process.env.REACT_APP_ENV === 'test'){
    return new HttpLink({
      uri: `https://${subdomain}.rovelabs.com/test/graphql`,
      credentials: 'include',
     
    });
  } else {
    return new HttpLink({
      uri: `https://${subdomain}.rovelabs.com/graphql`,
      credentials: 'include',
     
    });
  }
};

const authLink = new ApolloLink((operation, forward) => {
    const accessToken = Cookies.get('accessToken');
    const idToken = Cookies.get('idToken')
    console.log("In Auth");
    //const authHeader = context.response.headers.get("Authorization");
    operation.setContext({
        headers: {
           // ...authHeader,
          authorization: `Bearer ${accessToken}`,
          idToken : idToken

        },
      })
    
      return forward(operation)
});

 
const link =  ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        console.log(graphQLErrors)
        // User access token has expired
        if (graphQLErrors && graphQLErrors[0].message === 'TokenExpiredError') {
          // We assume we have both tokens needed to run the async request
            // Let's refresh token through async request
            
            return new Observable(async observer => {
           
              let accessToken = '';
              let idToken = '';

              if(process.env.REACT_APP_ENV === 'test'){
                await axios.post("https://adminapi.rovelabs.com/auth/tokenRefresh",{withCredentials:true})
                .then(tokenRefreshResponse => {
                  
                  
                  accessToken = tokenRefreshResponse.data.auth.access_token;
                  idToken = tokenRefreshResponse.data.auth.id_token;
                  Cookies.set('accessToken',accessToken);
                  Cookies.set('idToken',idToken)
                  const context = operation.getContext();
                  const authHeader = context.response.headers.get("Authorization");
                  operation.setContext({
                    headers: {
                        ...authHeader,
                      authorization: `Bearer ${accessToken}`,
                      idToken: idToken,
                     
                    },
                  })
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  };
  
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch(error => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
              } else {
                await axios.post("https://adminapi.rovelabs.com/auth/tokenRefresh",{withCredentials:true})
                .then(tokenRefreshResponse => {
                  console.log(tokenRefreshResponse);
                  
                  accessToken = tokenRefreshResponse.data.auth.access_token;
                  idToken = tokenRefreshResponse.data.auth.id_token;
                  Cookies.set('accessToken',accessToken);
                  Cookies.set('idToken',idToken)
                  const context = operation.getContext();
                  const authHeader = context.response.headers.get("Authorization");
                  operation.setContext({
                    headers: {
                        ...authHeader,
                      authorization: `Bearer ${accessToken}`,
                      idToken: idToken,
                     
                    },
                  })
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer)
                  };
  
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
                .catch(error => {
                  // No refresh or client token available, we force user to login
                  observer.error(error);
                });
              }
            });
          
        }
      })
    ]);

const client = (PORT) =>  new ApolloClient({
    link: from([authLink, link, httpLink(PORT)]),
    cache: new InMemoryCache({
       addTypename: false
    })
});

export default client;